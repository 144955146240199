.form-table-text-field {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;

    @media screen and (max-width: 991px) {
        &--mobile {
            display: flex;
            flex-direction: column;
            border: 0px solid coral;
            background-color: #FFF;
            padding: 2px 0px;
            padding-top:5px;
        }

        &__label {
            font-family: 'PoppinsRegular', Arial, sans-serif;
            font-size: 13px;
            color: #798086;
            margin-bottom: 0px;
            padding-bottom: 1px;
            padding-left: 10px;

            &__required {
                color: #D51B15;
            }
        }
    }

    &__input {
        border: 1px solid #FFFFFF;
        border-bottom: 1px solid #EFEFEF;
        height: 38px;
        background-color: #FFFFFF;
        text-indent: 10px;
        border-radius: 0px;
        font-size: 14px;
        margin: 0px;

        &--error {
            border: 1px solid #D51B15;
        }

        &--right {
            text-align: right;
            padding-right: 10px;
        }

        @media screen and (max-width: 991px) {
            &--mobile {
                height: 21px;
                text-align: left;
                border-bottom: 1px solid #FFF;
                padding: 2px 0;
            }
        }

        &--warning {
            color: #D51B15;
            font-weight: 700;
        }
    }

    &__input:focus {
        outline: 0px solid #3084B7;
    }

}