.document-body {
    background-color: #EBEEF0;
    padding: 30px 10% !important;
    margin-top: 20px;
    margin-bottom: 70px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    min-width: 1260px;

    &__spinner {
        margin: 0px auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -20px;
    }

    &__error {
        padding: 40px;
        background-color: #FFFFFF;
        border-radius: 10px;
    }

    &__header {
        width: 100%;
        border-bottom: 1px solid #CCCCCC;
        display: flex;
        justify-content: space-between;
        padding: 0px !important;
    }

    &__body {
        display: flex;
        // flex-wrap: wrap;
        padding: 0px !important;
    }

    &__table {
        margin-top: 40px;
        padding: 0px !important;
    }

    &__total {
        margin-top: 30px;
    }

    &__a4 {
        background-color: #FFFFFF;
        width: 100%;
        padding: 50px;
        
        &__watermark {
            display: none;

            @media print {
                display: inline;
                position: fixed !important;
                opacity: 0.1;
                font-size: 132px;
                width: 100%;
                text-align: center;
                z-index: 1000;
                top: 25%;
                right: 5px;
                transform: rotate(45deg);
            }
        }
    }

    @media (min-width: 1200px) {
        max-width: 1260px !important;
    }
}