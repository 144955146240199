.document-body-section {
    margin-top: 40px;
    padding: 0px !important;
    padding-bottom: 10px !important ;

    &__title {
        font-family: 'PoppinsRegular', Arial, sans-serif;
        font-size: 15px;
        text-transform: capitalize;
        font-weight: 600;

        &--large{
            font-size:22px
        }
    }

    &__list {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        font-size: 15px;
        text-align: left;
        font-weight: 600;

        &__title-text{
            display: flex;
            justify-content: flex-start;

            &__title{
                font-weight: 600;
            }

            &__text{
                margin-left: 7px;
            }
        }
    }
    &--right{
        text-align: right
    }
}