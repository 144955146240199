.form-data-display-table-container{
    padding: 0 10px;
}
.form-data-display-table{
    width: 100%;

    &__header{

        &__cell{
            border-bottom: 1px solid #e9e9e2;
            font-weight: 400;
            color:#666;
            padding: 10px;
            padding-left: 0px;
        }
    }

    &__body{
        background-color: #FFF;

        &__cell{
            border-bottom: 1px solid #F7F7F7;
            border-right:0px;
            font-weight: 400;
            color:#00030A;
            padding: 10px;
            padding-left: 0px;
        }

        &__empty-message{
            text-align: center;
            padding: 10px ;
            padding-top: 20px;
            color: #333;
            border: 1px solid #F7F7F7
        }
    }

    &__total{
        max-width: 300px;
        margin-top: 10px;
        padding: 10px;
        text-align: left;
        background-color: #F8F9FA;
        border-radius: 10px;
    }
}